import React from "react";
import "../styles/global.css";
import "../styles/tncPageGDocs.css";
import "../styles/tncPageCustom.css";
import Navbar from "../components/Navbar/Navbar";
import { Helmet } from "react-helmet";
import Footer from "../sections/Footer.section";

function TncPage(props) {
  const title = "Terms and Conditions";
  const description =
    "Terms and Conditions for CricTez. Collect limited edition cricket collectibles(NFTs), trade on marketplace & compete in live matches to earn exclusive rewards";
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://crictez.io/" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content="https://crictez.io/static/preview_banner-28bcf520d1851fc885c974d66b714900.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://crictez.io/" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta
          property="twitter:image"
          content="https://crictez.io/static/preview_banner-28bcf520d1851fc885c974d66b714900.png"
        />
      </Helmet>
      <Navbar />
      <div className={"tnc-wrapper"}>
        <p>Last Updated on - 5th July 2021</p>
        <h1 className="c18" id="h.lce2wsoyql7d">
          <span className="c24">Terms and Conditions</span>
        </h1>

        <h2 className="c12" id="h.cx1pdkta1blu">
          <span className="c7">Article 1 &ndash; Definitions</span>
        </h2>
        <ul className="c16 lst-kix_g7i274e3e3o3-0 start">
          <li className="c3 li-bullet-0">
            <span className="c1">Buyer</span>
            <span className="c2">
              &nbsp;Refers to the User acquiring a Collectible on the Crictez
              Marketplace.
            </span>
          </li>
          <li className="c3 li-bullet-0">
            <span className="c1">Collectible</span>
            <span className="c2">
              &nbsp;Refers to the digital cards featuring a professional cricket
              player&rsquo;s original artwork, as well as the underlying tokens
              that can be used on a blockchain.
            </span>
          </li>
          <li className="c3 li-bullet-0">
            <span className="c1">Cryptocurrency</span>
            <span className="c2">
              &nbsp;Refers to any digital asset designed to work as a medium of
              exchange on blockchain and accepted in payment throughout the
              Services.
            </span>
          </li>
          <li className="c3 li-bullet-0">
            <span className="c1">Currency</span>
            <span className="c2">
              &nbsp;Refers to any currency being legal tender (euro, dollar,
              etc.).
            </span>
          </li>
          <li className="c3 li-bullet-0">
            <span className="c1">Marketplace</span>
            <span className="c2">
              &nbsp;Refers to the service offered by Crictez that allows Users
              to exchange or sell Collectibles amongst them.
            </span>
          </li>
          <li className="c3 li-bullet-0">
            <span className="c1">NFT</span>
            <span className="c2">
              &nbsp;Refers to the Non-Fungible Token (also known as Crypto-good
              or Crypto-collectible) which represents on blockchain the
              Collectible digital card. This token ensures the scarcity of the
              Collectible and the transparency of the service.
            </span>
          </li>
          <li className="c3 li-bullet-0">
            <span className="c1">Seller</span>
            <span className="c2">
              &nbsp;Refers to the User offering to sale his own Collectible on
              the Service&rsquo; Marketplace.
            </span>
          </li>
          <li className="c3 li-bullet-0">
            <span className="c1">Services</span>
            <span className="c10">
              &nbsp;Refers to every services accessible on crictez.io with a
              User account, namely the Drops section and the Marketplace
            </span>
            <span className="c2">&nbsp;Section.</span>
          </li>
          <li className="c3 li-bullet-0">
            <span className="c1">Terms and Conditions (T&amp;Cs)</span>
            <span className="c2">
              &nbsp;These Terms and Conditions constitute a legally binding
              agreement (hereinafter, the &quot;T&amp;Cs&quot;) between you and
              Crictez (as defined above) governing your access to and use of the
              Website and all associated services (hereinafter, the
              &quot;Services&quot;).
            </span>
          </li>
          <li className="c3 li-bullet-0">
            <span className="c1">Third-Party Services</span>
            <span className="c2">
              &nbsp;Refers to the services not operated by Crictez, but use to
              provides content or additional features to the Users such as
              payment service, content on players, marketplaces of collectibles,
              etc.
            </span>
          </li>
          <li className="c3 li-bullet-0">
            <span className="c1">User</span>
            <span className="c2">
              &nbsp;Refers to any natural person or legal person using the
              services offered by the Service or visiting its website with a
              full legal capacity and who is not acting for purposes relating to
              his trade, business, craft or profession.
            </span>
          </li>
          <li className="c5 c15 li-bullet-0">
            <span className="c1">Website</span>
            <span className="c2">
              &nbsp;Refers to the website www.crictez.io including any
              subdomains thereof, and any other websites through which Crictez
              makes its services available.
            </span>
          </li>
        </ul>
        <h2 className="c12" id="h.lfta3adt7gnk">
          <span className="c7">Article 2 &ndash; Scope</span>
        </h2>
        <p className="c5">
          <span className="c1">2.0.1</span>
          <span className="c2">
            &nbsp;Crictez (the &ldquo;Service&rdquo;) is an online website that
            enables registered users (the &ldquo;User&rdquo;) to acquire,
            collect and exchange collectible digital card featuring professional
            cricket players original art-work (the &ldquo;Collectible&rdquo;)
            and the underlying token based on blockchain technology (the
            &ldquo;NFT&rdquo;). The service is available on the website
            www.crictez.io (the &ldquo;Website&rdquo;) and allows its Users to
            buy, transfer, sell or exchange collectible cards, either within the
            Service or with third parties.
          </span>
        </p>
        <p className="c5">
          <span className="c1">2.0.3</span>
          <span className="c2">
            &nbsp;These T&amp;Cs apply, without restriction or reservation, to
            any use of the Services offered by the Service to consumers and
            non-professional Users on its Website. At the time the User chooses
            to sell a Collectible on the Marketplace, he/she fully and
            unconditionally accepts these T&amp;Cs.
          </span>
        </p>
        <p className="c5">
          <span className="c1">2.0.4</span>
          <span className="c2">
            &nbsp;The User acknowledges that he/she has, in his/her country, the
            capacity to contract and to subscribe to the Service and declares
            that he/she has read and accepted the T&amp;Cs by ticking the box
            provided for this purpose. The account creation by the User
            constitutes acceptance without restriction or reservation of these
            T&amp;Cs.
          </span>
        </p>
        <p className="c5">
          <span className="c1">2.0.5</span>
          <span className="c2">
            &nbsp;Unless proven otherwise, the data recorded in the service
            provider&#39;s computer system constitutes proof of all transactions
            concluded by the User and the Service via the website.
          </span>
        </p>
        <p className="c5">
          <span className="c1">2.0.6</span>
          <span className="c2">
            &nbsp;These T&amp;Cs are accessible at any time on the website and
            will prevail over any other version or any other contradictory
            document.
          </span>
        </p>
        <p className="c5">
          <span className="c1">2.0.7</span>
          <span className="c2">
            &nbsp;As these T&amp;Cs may be subject to subsequent modifications,
            the version applicable to the User&#39;s purchase is the one in
            force on the website on the date of placing the order.
          </span>
        </p>
        <p className="c5">
          <span className="c1">2.0.8</span>
          <span className="c2">
            &nbsp;The Service may contain links or content from third-party
            websites or resources (&ldquo;Third-Party Services&rdquo;). Such
            Third-Party Services may be subject to different terms and
            conditions and privacy practices. The Service is not responsible or
            liable for the availability or accuracy of such Third-Party
            Services, or the content, products, or services available from such
            Third-Party Services. Links to such Third-Party Services are not an
            endorsement by the Service of such Third-Party Services.
          </span>
        </p>
        <h2 className="c12" id="h.igu4s3k6sv94">
          <span className="c7">Article 3 &ndash; Account</span>
        </h2>
        <h3 className="c23" id="h.4xfd2wri19j">
          <span className="c6">Article 3.1 &ndash; Creation and access</span>
        </h3>
        <p className="c5">
          <span className="c1">3.1.1</span>
          <span className="c2">
            &nbsp;Any User can create an account on the Service by:
          </span>
        </p>
        <ul className="c16 lst-kix_t95uksmxnv26-0 start">
          <li className="c11 li-bullet-0">
            <span className="c2">
              providing his/her username, e-mail address; and
            </span>
          </li>
          <li className="c11 li-bullet-0">
            <span className="c2">choosing a (strong) password;</span>
          </li>
          <li className="c14 li-bullet-0">
            <span className="c2">
              accepting the Terms and conditions and the Game Rules.
            </span>
          </li>
          <li className="c14 li-bullet-0">
            <span className="c10">Or by choosing </span>
            <span className="c2">Google OAuth.</span>
          </li>
        </ul>
        <p className="c5">
          <span className="c1">3.1.2</span>
          <span className="c2">
            &nbsp;The Service shall not be held liable for the loss or theft of
            a password by the User, or by the resulting account impersonation.
          </span>
        </p>
        <p className="c5">
          <span className="c1">3.1.3</span>
          <span className="c2">
            &nbsp;It is the User&#39;s responsibility to take all measures to
            secure access to this data.
          </span>
        </p>
        <h3 className="c8" id="h.jvqs36ls3cwq">
          <span className="c17">Article 3.2 &ndash; </span>
          <span className="c17">Deletion</span>
        </h3>
        <p className="c5">
          <span className="c1">3.2.1</span>
          <span className="c2">
            &nbsp;The User may, at any time, request the deletion of his/her
            account under the conditions set out by the Website.
          </span>
        </p>
        <p className="c5">
          <span className="c1">3.2.2</span>
          <span className="c2">
            &nbsp;The deletion of an account irreversibly leads to the deletion
            of all its data.{" "}
          </span>
        </p>
        <h3 className="c8" id="h.50vncobu9wah">
          <span className="c6">Article 3.3 &ndash; Wallet</span>
        </h3>
        <p className="c5">
          <span className="c1">3.3.1</span>
          <span className="c2">
            &nbsp;The Service provides each account with options to connect
            external wallets provided by other services.
          </span>
        </p>
        <p className="c5">
          <span className="c1">3.3.2</span>
          <span className="c2">
            &nbsp;Users are free to use the Wallet to execute transactions
            within the Service. He/She may credit or debit the Wallet with
            his/her own Cryptocurrency and Collectibles at any time, within the
            limits defined by the Service.
          </span>
        </p>
        <p className="c5">
          <span className="c1">3.3.3</span>
          <span className="c2">
            &nbsp;Wallets rely on an experimental technology independent of the
            Service. Therefore, the Service should not be held liable for any
            technical default, hacking or loss of the data stored on the Wallet.
          </span>
        </p>
        <h2 className="c12" id="h.desw66ble4vn">
          <span className="c7">Article 4 &ndash; Services</span>
        </h2>
        <h3 className="c8" id="h.jysfarfwsbg8">
          <span className="c6">
            Article 4.1 &ndash; Collectible features and characteristics
          </span>
        </h3>
        <p className="c5">
          <span className="c1">4.1.1</span>
          <span className="c2">
            &nbsp;Collectibles are issued by the Service based on a level of
            scarcity specified on each item. For instance, a Collectible may be
            standard, rare or epic, regarding the total number of items offered.
            The scarcity of a Collectible is guaranteed by the underlying NFT,
            that can be monitored directly on blockchain.
          </span>
        </p>
        <p className="c5">
          <span className="c1">4.1.</span>
          <span className="c1">2</span>
          <span className="c10">
            &nbsp;Collectible card effigies are allowed under agreements between
            the Service and the natural or legal persons concerned. All
            contractual provisions between the Service and these persons are
            enforceable against Users.
          </span>
        </p>
        <h3 className="c8" id="h.q6zmoe1rrv6d">
          <span className="c6">
            Article 4.2 &ndash; Issuance of Collectible
          </span>
        </h3>
        <p className="c5">
          <span className="c1">4.2.1</span>
          <span className="c2">
            &nbsp;The Service offers Collectibles for sale by way of auctions in
            which the Service only sets the floor price before Users bid up for
            a specified period of time. The Buyer is the last bidder. There is
            no reservation price.
          </span>
        </p>
        <p className="c5">
          <span className="c2">
            During the auction process, the Service keeps the Cryptocurrency
            funds under escrow to ensure payment. The funds of participants
            other than the Buyer are automatically given back whenever a new bid
            higher than the bid of the previous bidder is placed.
          </span>
        </p>
        <p className="c5">
          <span className="c1">4.2.2</span>
          <span className="c10">
            &nbsp;The Service collects a fee from the sale price of the
            Collectible automatically at the end of the auction. The amount of
            this commission is specified in the FAQ and may be changed at any
            time by the Service.
          </span>
        </p>
        <p className="c5 c22">
          <span className="c0"></span>
        </p>
        <p className="c5">
          <span className="c0">
            BY PURCHASING A COLLECTIBLE ON THE WEBSITE, THE USER EXPRESSLY
            WAIVES HIS/HER RIGHT OF WITHDRAWAL.
          </span>
        </p>
        <h3 className="c8" id="h.i5shz1jrvujd">
          <span className="c6">Article 4.3 &ndash; Transfer and exchange</span>
        </h3>
        <p className="c5">
          <span className="c1">4.3.1</span>
          <span className="c2">
            &nbsp;User has the possibility to exchange his/her Collectibles on
            the Service&#39;s Marketplace or through a third party service.
          </span>
        </p>
        <p className="c5">
          <span className="c1">4.3.2</span>
          <span className="c2">
            &nbsp;The Service is under no circumstances liable for any damage
            that may occur during the transfer or exchange of Collectibles
            outside the Service.
          </span>
        </p>
        <h3 className="c8" id="h.79ej1z58gcli">
          <span className="c6">Article 4.4 &ndash; Game</span>
        </h3>
        <p className="c5">
          <span className="c1">4.4.1</span>
          <span className="c2">
            &nbsp;The game, entitled &quot;Fantasy&quot;, is based on a virtual
            tournament that tracks the real performance of players on the field,
            accessible from a dedicated area (&quot;Gaming Arena&quot;) on the
            Website.
          </span>
        </p>
        <h3 className="c8" id="h.prw38niv78ld">
          <span className="c6">Article 4.5 &ndash; Support</span>
        </h3>
        <p className="c5">
          <span className="c1">4.5.1</span>
          <span className="c2">
            &nbsp;Support is available at this email address:
            support@crictez.io.
          </span>
        </p>
        <p className="c5">
          <span className="c1">4.5.2</span>
          <span className="c2">
            &nbsp;The Service undertakes to respond as quickest as possible,
            depending on the number of requests in process.
          </span>
        </p>
        <h2 className="c12" id="h.kn7fc5ngfzs1">
          <span className="c7">Article 5 &ndash; Marketplace</span>
        </h2>
        <p className="c5">
          <span className="c1">5.0.1</span>
          <span className="c2">
            &nbsp;The Service provides a Marketplace that allows Users to
            exchange amongst them Collective against Cryptocurrency or
            Collectible.
          </span>
        </p>
        <p className="c5">
          <span className="c1">5.0.2</span>
          <span className="c2">
            &nbsp;This article shall apply only to the relations between Buyer
            and Seller in the context of the exchange of a Collectible on the
            Website.
          </span>
        </p>
        <p className="c5">
          <span className="c1">5.0.3</span>
          <span className="c2">
            &nbsp;The use of the Marketplace is subject to the unconditional
            acceptance and respect of these T&amp;Cs and specifically of the
            following articles.
          </span>
        </p>
        <h3 className="c8" id="h.puo288ae0gsl">
          <span className="c6">Article 5.1 &ndash; Sale and exchange</span>
        </h3>
        <p className="c5">
          <span className="c1">5.1.1</span>
          <span className="c2">
            &nbsp;Users holding Collectibles have the ability to sell them
            throughout the Marketplace when the Collectible is available (e.g.
            not locked into a team within the Fantasy game).
          </span>
        </p>
        <p className="c5">
          <span className="c1">5.1.2</span>
          <span className="c2">
            &nbsp;At the time of the sale, the User chooses a fixed price for
            the sale of his/her Collectible. The Service does not intervene in
            any way in the sale between Buyer and Seller within the Marketplace.
            Consequently, the Service never acts as a counterpart to this sale
            and does not take part in the determination of the price.
          </span>
        </p>
        <p className="c5">
          <span className="c1">5.1.3</span>
          <span className="c2">
            &nbsp;In return for making the Marketplace available, the Service
            charges a commission on the sale of the Collectibles of an amount
            that is always specified in advance to the parties.{" "}
          </span>
        </p>
        <p className="c5">
          <span className="c1">5.1.4</span>
          <span className="c2">
            &nbsp;During the sale, and unless the Seller stops the sale before
            its expiration date, the Collectible is no longer accessible by its
            owner. On the sale expiration date, if no one has bought the
            Collectible it is returned to its owner who put it on sale in the
            marketplace.
          </span>
        </p>
        <p className="c5">
          <span className="c1">5.1.5</span>
          <span className="c2">
            &nbsp;It is expressly agreed that payment for the Collectible will
            be made using the Cryptocurrencies supported by the Service. Payment
            will be made concurrently with the transfer of the Collectible. The
            Seller shall bear the exchange-rate risk on the value of the
            Cryptocurrency into fiat currency at the time of transfer and at the
            end of the sale.
          </span>
        </p>
        <h3 className="c8" id="h.w4g8gocn5wvc">
          <span className="c6">Article 5.2 &ndash; Referencing</span>
        </h3>
        <h3 className="c8" id="h.72ht1duhlcrw">
          <span className="c6">Article 5.3 &ndash; Reviews</span>
        </h3>
        <h3 className="c8" id="h.lnlb5mfiijjq">
          <span className="c6">
            Article 5.4 &ndash; Tax and social security obligations
          </span>
        </h3>
        <p className="c5">
          <span className="c1">5.4.1</span>
          <span className="c2">
            &nbsp;The Service draws the attention of the Users that receiving
            Cryptocurrencies in return for the sale of their Collectibles is
            likely to constitute an income subject to tax and social
            contributions.
          </span>
        </p>
        <p className="c5">
          <span className="c1">5.4.2</span>
          <span className="c2">
            &nbsp;It is the Users sole responsibility to declare the income from
            the Sales of their Collectible to the competent Tax Administration
            and Social Security Administration.
          </span>
        </p>
        <h3 className="c8" id="h.66y8swnmdupi">
          <span className="c6">
            Article 5.5 &ndash; Disputes on the Marketplace
          </span>
        </h3>
        <p className="c5">
          <span className="c1">5.5.1</span>
          <span className="c2">
            &nbsp;As part of its Marketplace service, the Service acts as a mere
            intermediary between Sellers and Buyers. As such, the Service shall
            not be held liable for damages caused by a sale, due to the price or
            an act done by one of the parties.
          </span>
        </p>
        <p className="c5">
          <span className="c1">5.5.2</span>
          <span className="c2">
            &nbsp;In addition, the services provided through the Marketplace
            takes place only between the Buyer and the Seller. The
            Service&rsquo;s responsibility cannot be incurred in respect of such
            services from which the Service is unconcerned.
          </span>
        </p>
        <p className="c5">
          <span className="c1">5.5.3</span>
          <span className="c2">
            &nbsp;Therefore, any claim must be addressed by the Buyer to the
            Seller. The Seller is solely responsible for the claims processing.
            Nevertheless, the Service reserves the right to take part in
            arbitration, in its discretion, if the Seller does not provide a
            response to a request nor a settlement to a dispute.
          </span>
        </p>
        <h2 className="c12" id="h.fzxnwaklx3jh">
          <span className="c4">Article 6 &ndash; Liability of the Users</span>
        </h2>
        <p className="c5">
          <span className="c1">6.0.1</span>
          <span className="c2">
            &nbsp;The User guarantees the Service against any breach, exceeding
            the foreseeable risk, that could result from the use of the Service
            by a User.
          </span>
        </p>
        <p className="c5">
          <span className="c1">6.0.2</span>
          <span className="c2">
            &nbsp;The User undertakes to use the Service only in accordance with
            the T&amp;Cs. It is not authorized to reconstruct the Service,
            decompile, disassemble or circumvent the technical restrictions it
            contains, except to the extent that such operations would be
            permitted by applicable laws. It is also prohibited to lend, rent,
            lease, resell, transfer or host the Service to or for third parties,
            unless expressly authorized by the Service.
          </span>
        </p>
        <p className="c5">
          <span className="c1">6.0.3</span>
          <span className="c2">
            &nbsp;The User guarantees the Service against fraudulent, unfair or
            bad faith use of the service, in particular if this interferes with
            the use of the service by other Users.
          </span>
        </p>
        <p className="c5">
          <span className="c1">6.0.4</span>
          <span className="c2">
            &nbsp;The Service reserves the right to suspend or delete a
            User&#39;s account in violation of these T&amp;C, without prior
            notice or compensation.
          </span>
        </p>
        <h2 className="c12" id="h.p8nm3hwy72cu">
          <span className="c7">Article 7 &ndash; Liability of the Service</span>
        </h2>
        <h3 className="c8" id="h.vp5uldvx1aww">
          <span className="c6">Article 7.1 &ndash; Liability limitation</span>
        </h3>
        <p className="c5">
          <span className="c1">7.1.1</span>
          <span className="c10">
            &nbsp;The Service undertakes to implement all the necessary means to
            ensure the best{" "}
          </span>
          <span className="c2">
            delivery of the services provided. However, the Service can only be
            bound by an obligation of best endeavour.
          </span>
        </p>
        <p className="c5">
          <span className="c1">7.1.2</span>
          <span className="c2">
            &nbsp;In the event of failure of the Service, it shall not be liable
            for any direct and indirect damages.{" "}
          </span>
        </p>
        <p className="c5">
          <span className="c2">
            In any event, the Service may not be held liable for any amount, not
            even the amout invoiced by the user to the service at any period.
          </span>
        </p>
        <p className="c5">
          <span className="c1">7.1.3</span>
          <span className="c2">
            &nbsp;In particular, the Service shall not be held liable for:
          </span>
        </p>
        <ul className="c16 lst-kix_4ey84neirsxq-0 start">
          <li className="c11 li-bullet-0">
            <span className="c2">
              breakdowns or malfunctions of the services offered;
            </span>
          </li>
          <li className="c11 li-bullet-0">
            <span className="c2">
              data losses of Users not being imputable to it;
            </span>
          </li>
          <li className="c11 li-bullet-0">
            <span className="c2">
              an unusual or illegal use of the services offered, by a third
              party or by a User;
            </span>
          </li>
          <li className="c11 li-bullet-0">
            <span className="c2">
              direct or indirect damage resulting from the use of the Service
              offered;
            </span>
          </li>
          <li className="c11 li-bullet-0">
            <span className="c2">a malfunction or cyber attack;</span>
          </li>
          <li className="c11 li-bullet-0">
            <span className="c2">
              an adverse event during the auction, both in the course of the
              process and at the stage of establishing the floor price and the
              final price ;
            </span>
          </li>
          <li className="c14 li-bullet-0">
            <span className="c10">
              any case of force majeure, within the meaning of Article 1218 of
              the French civil code, including a failure of the Blockchain
              protocol used to run the Service.
            </span>
          </li>
        </ul>
        <h2 className="c12" id="h.t0lhfdnla5ut">
          <span className="c7">Article 8 &ndash; Intellectual property</span>
        </h2>
        <p className="c5">
          <span className="c1">8.1.1</span>
          <span className="c2">
            &nbsp;The User grants to the Service the right of exclusive
            exploitation, transferable, sublicensable, free of charge, for the
            whole world on the content he/she produces on the Service (Team
            composition, team name, etc.).
          </span>
        </p>
        <p className="c5">
          <span className="c1">8.1.2</span>
          <span className="c2">
            &nbsp;The rights thus granted include the right to reproduce,
            represent, distribute, adapt, modify, create a derivative work,
            translate all or part of the content by any means, in any form
            whatsoever and on all media known or unknown to date as part of the
            service of the Service or in line with its activities, and this for
            commercial purposes or not and advertising, as well as to broadcast
            on social networks that the Service uses.
          </span>
        </p>
        <h3 className="c8" id="h.s4quy2lw1s4e">
          <span className="c6">Article 8.2 &ndash; Service content</span>
        </h3>
        <p className="c5">
          <span className="c1">8.2.1</span>
          <span className="c2">
            &nbsp;All intellectual property rights on the website content,
            excluding the Users generated content, are the Service property.
            They are provided free of charge within the exclusive framework of
            the use of the Service. Therefore, any use of these contents without
            the prior permission of the Service is unlawful.
          </span>
        </p>
        <p className="c5">
          <span className="c1">8.2.2</span>
          <span className="c2">
            &nbsp;The Service is the service&rsquo;s database producer. Any
            unauthorized extraction or reproduction is prohibited.
          </span>
        </p>
        <h2 className="c12" id="h.njy6ghuvxhfo">
          <span className="c7">Article 9 &ndash; Privacy policy</span>
        </h2>
        <h3 className="c8" id="h.6otbigc14tef">
          <span className="c6">Article 9.1 &ndash; Personal data</span>
        </h3>
        <p className="c5">
          <span className="c1">9.1.1</span>
          <span className="c2">
            &nbsp;The Service is required to collect some Users&rsquo; personal
            data, by any means and, in particular, during the User account
            creation. The communication of the personal data requested by the
            Service by Users is strictly necessary for the services performance.
            Therefore, its communication is essential for the fulfillment of the
            parties&rsquo; contractual obligations. Consequently, without the
            requested information communication, the Service will not be able to
            establish contractual relations with the User.
          </span>
        </p>
        <p className="c5">
          <span className="c1">9.1.2</span>
          <span className="c2">
            &nbsp;Users agree that their personal data will be transmitted by
            the Service to other Users or, if needed, to their representatives,
            for the sole purpose of enabling the Service performance and under
            the provided conditions for herein.
          </span>
        </p>
        <p className="c5">
          <span className="c1">9.1.3</span>
          <span className="c2">
            &nbsp;The personal data collected by the Service shall be stored for
            the time necessary to fulfill the Parties contractual obligations,
            subject to the legal obligations of the Service.
          </span>
        </p>
        <p className="c5">
          <span className="c1">9.1.4</span>
          <span className="c2">
            &nbsp;The Service implements appropriate technical and
            organizational measures to ensure the security of personal data
            collected from its Users. However, in case of doubt by the User on
            the security of the protection of his personal data and in
            particular, on the security of his personal account, the User agrees
            to expose his situation to the Service as soon as possible.
          </span>
        </p>
        <p className="c5">
          <span className="c1">9.1.5</span>
          <span className="c10">
            &nbsp;Users have the right to access, rectify and delete their
            personal data. Anyone may also, for legitimate reasons, oppose the
            processing of their data. Users may exercise these rights at any
            time by sending their request to the following email address :
            support
          </span>
          <span className="c10">@crictez.</span>
          <span className="c2">io.</span>
        </p>
        <h3 className="c8" id="h.yyu89h6rqup4">
          <span className="c6">Article 9.2 &ndash; Cookies</span>
        </h3>
        <p className="c5">
          <span className="c1">9.2.1</span>
          <span className="c2">
            &nbsp;During the consultation of the Service, cookies may be
            registered on the User&#39;s electronic communications equipment to
            make the website use easier and to collect statistics relating to
            attendance and access.
          </span>
        </p>
        <p className="c5">
          <span className="c1">9.2.2</span>
          <span className="c2">
            &nbsp;Cookies are text files that the website records on the
            terminal from which it is accessed in order to store various
            technical data to facilitate the website use and to collect
            statistical information about its Users.
          </span>
        </p>
        <p className="c5">
          <span className="c1">9.2.3</span>
          <span className="c2">
            &nbsp;Users are informed beforehand of the store on their terminal
            information. Users are free to accept or refuse the storage of
            cookies. Continuing to navigate on the website after having been
            informed means that the User accepts the data collection.
          </span>
        </p>
        <h2 className="c12" id="h.4lzbj6kuhm28">
          <span className="c7">Article 10 &ndash; Hyperlink</span>
        </h2>
        <p className="c5">
          <span className="c2">
            The Service may contain hypertext links redirecting to third party
            sites. The Service has no control over these sites and declines all
            responsibility for access, content or use of these sites.
          </span>
        </p>
        <h2 className="c12" id="h.tyijlb6crqxg">
          <span className="c7">Article 11 &ndash; Amendment</span>
        </h2>
        <p className="c5">
          <span className="c1">11.0.1</span>
          <span className="c2">
            &nbsp;The Service reserves the right to modify or stop the
            accessibility of all or part of the services and/or the Website or
            Applications at any time.
          </span>
        </p>
        <p className="c5">
          <span className="c1">11.0.2</span>
          <span className="c2">
            &nbsp;The Service reserves the right to modify, at any time, all or
            part of the T&amp;Cs. Users&#39; use of the Service constitutes
            their acceptance of the T&amp;C amendment. The applicable version of
            the T&amp;Cs is the latest version published on the date of use of
            the services offered by the Service.
          </span>
        </p>
        <h2 className="c12" id="h.ra30ttyzolt8">
          <span className="c7">Article 12 &ndash; Non waiver</span>
        </h2>
        <p className="c5">
          <span className="c1">12.0.1</span>
          <span className="c2">
            &nbsp;Failure by the Service at any time to require the other party
            to perform any of its obligations under these T&amp;Cs will not
            affect the right of the Service to request their execution
            thereafter.
          </span>
        </p>
        <p className="c5">
          <span className="c1">12.0.2</span>
          <span className="c2">
            &nbsp;The nullity of any clause of these T&amp;Cs does not affect
            the validity and the obligation to respect the other clauses.
          </span>
        </p>
        <h2 className="c12" id="h.drmgesbp78a9">
          <span className="c7">Article 13 &ndash; Applicable law</span>
        </h2>
        <h3 className="c8" id="h.m0bujqik4ylw">
          <span className="c1">13.0.1 &ndash;</span>
          <span className="c17">&nbsp;</span>
          <span className="c19">
            These T&amp;Cs are governed by Indian law solely.
          </span>
        </h3>
        <h2 className="c12" id="h.82legqp9tr2l">
          <span className="c4">Article 14 &ndash; Termination</span>
        </h2>
        <p className="c5">
          <span className="c1">14.0.1</span>
          <span className="c10">&nbsp;</span>
          <span className="c19">
            Notwithstanding anything contained in these Terms, we reserve the
            right, without notice and in our sole discretion, to terminate your
            right to access or use the Service at any time and for any or no
            reason, and you acknowledge and agree that we shall have no
            liability or obligation to you in such event and that you will not
            be entitled to a refund of any amounts that you have already paid to
            us, to the fullest extent permitted by applicable law.
          </span>
        </p>
        <h3 className="c21" id="h.jyet0wxmnyaq">
          <span className="c13"></span>
        </h3>
      </div>
      <Footer />
    </>
    //    TODO: Remove google redirect
  );
}

export default TncPage;
